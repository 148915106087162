@font-face {
  font-family: 'Mark OT';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('/public/font/MarkOT.otf') format("truetype");
}
@font-face {
  font-family: 'Mark OT';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('/public/font/MarkOT-Light.otf') format("truetype");
}
@font-face {
  font-family: 'Mark OT';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('/public/font/MarkOT-Medium.otf') format("truetype");
}
@font-face {
  font-family: 'Mark OT';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('/public/font/MarkOTBold.otf') format("truetype");
}

.App {
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: hidden;
  font-family: 'Mark OT', Arial, Helvetica, sans-serif !important;
  font-weight: 400;
}

header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 5rem;
  padding: 1rem 0;
  border-bottom: 3px solid #3FC7F2;
}

header nav {
  padding: 0 !important;
}

header img {
  height: 2.5rem;
}

main {
  position: absolute;
  top: 5rem;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: 2rem;
}

h1 {
  color: #3FC7F2;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

h3 {
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 2rem;
  color: #7AC143;
  text-align: center;
}

table {
  background-color: rgb(255, 254, 249);
}

.pink {
  color: #CE0954 !important;
}

.pink > th {
  color: #CE0954 !important;
}

.yellow {
  color: #FF9E00 !important;
}

.grey {
  color: #707070 !important;
}

.green {
  color: #7AC143 !important;
}

.blue {
  color: #3FC7F2 !important;
}

.delButton {
  background-color: #CE0954;
  border-color:#CE0954;
}

.delButton:hover {
  background-color: #ae0847;
  border-color:#CE0954;
}

.yellowButton {
  background-color: #FF9E00;
  border-color: #FF9E00;
}

.yellowButton:hover {
  background-color: #e88f00;
  border-color: #e88f00;
}

.greyButton {
  background-color: #707070;
  border-color: #707070;
}

.greyButton:hover {
  background-color: #505050;
  border-color: #505050;
}

#exportButton {
  background-color: #7AC143;
  border-color: #7AC143;
}

#exportButton:hover {
  background-color: green;
  border-color: green;
}

.loginButton {
  background-color: #3FC7F2;
  border-color: #3FC7F2;
}

.loginButton:hover {
  background-color: #3ab5db;
  border-color: #3ab5db;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #7AC143 #ddfdc4;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 7px;
  width: 7px;
}

*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #ddfdc4;
}

*::-webkit-scrollbar-track:hover {
  background-color: #e8fdd9;
}

*::-webkit-scrollbar-track:active {
  background-color: #ddfdc4;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #bcfa8c;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #85d24a;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #7AC143;
}
